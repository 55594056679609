<template>
  <div>
    <!--面包屑-->
    <div class="page-breadcrumb">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>教练列表</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <!--内容-->
    <div class="page-content-x">
      <!--搜索-->
      <div v-show="issearch" class="page-content-search">
        <el-form
          ref="form"
          :model="search"
          size="medium"
          :label-width="this.env.search_label_width"
        >
          <el-row>
            <el-col :span="6">
              <el-form-item label="姓名">
                <el-input v-model="search.user_name"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="昵称">
                <el-input v-model="search.user_nick"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="手机号">
                <el-input v-model="search.phone"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="身份证号">
                <el-input v-model="search.identity_card"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="城市">
                <el-input v-model="search.city"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="page-content-search-button">
            <el-col :span="24">
              <el-form-item>
                <el-button
                  icon="el-icon-search"
                  size="medium"
                  type="primary"
                  @click="is_search"
                  >搜索
                </el-button>
                <el-button
                  icon="el-icon-circle-close"
                  size="medium"
                  @click="init"
                  >清空搜索</el-button
                >
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <!--标题-->
      <el-row>
        <el-col :span="12"
          ><h3>{{ page_name }}</h3></el-col
        >
        <el-col :span="12">
          <div class="top-operation-button">
            <el-button
              v-if="issearch"
              icon="el-icon-caret-top"
              size="medium"
              type="primary"
              @click="issearch = !issearch"
              >隐藏搜索
            </el-button>
            <el-button
              v-else
              icon="el-icon-caret-bottom"
              size="medium"
              type="primary"
              @click="issearch = !issearch"
              >显示搜索
            </el-button>
          </div>
        </el-col>
      </el-row>
      <div style="height: 20px;"></div>
      <!--列表-->
      <el-table
        size="medium"
        border
        v-loading="loading"
        :data="tableData"
        style="width: 100%"
      >
        <el-table-column prop="user_nick" label="昵称"> </el-table-column>
        <el-table-column prop="user_name" label="姓名"> </el-table-column>
        <el-table-column prop="portrait" label="头像照片" width="80">
          <template slot-scope="scope">
            <el-avatar
              shape="square"
              :size="22"
              :src="scope.row.portrait"
            ></el-avatar>
          </template>
        </el-table-column>
        <el-table-column prop="avatar_url" label="微信头像" width="80">
          <template slot-scope="scope">
            <el-avatar
              shape="square"
              :size="22"
              :src="scope.row.avatar_url"
            ></el-avatar>
          </template>
        </el-table-column>
        <el-table-column prop="phone" label="手机号" width="200">
        </el-table-column>
        <el-table-column prop="gender" label="性别" width="160">
          <template slot-scope="scope">
            <el-tag size="mini" v-if="scope.row.gender === 0" type="info"
              >未知</el-tag
            >
            <el-tag size="mini" v-if="scope.row.gender === 1">男</el-tag>
            <el-tag size="mini" v-if="scope.row.gender === 2" type="danger"
              >女</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column prop="identity_card" label="身份证号" width="100">
        </el-table-column>
        <el-table-column prop="years" label="教学年限" width="130">
        </el-table-column>
        <el-table-column prop="city" label="城市" width="130">
        </el-table-column>
        <el-table-column prop="create_time" label="注册时间" width="150">
        </el-table-column>
        <el-table-column prop="update_time" label="更新时间" width="150">
        </el-table-column>

        <el-table-column fixed="right" label="操作" width="240">
          <template slot-scope="scope">
            <el-button
              v-if="is_auth('order.pt.getappointmentlist')"
              @click="openOrderModel(scope.row)"
              size="mini"
              >查看订单
            </el-button>
            <el-button
              v-if="is_auth('coach.coachpool.getinfo')"
              @click="tosee(scope.row.coach_uuid)"
              size="mini"
              >查看
            </el-button>
            <el-button
              v-if="is_auth('coach.coachpool.getinfo')"
              @click="changeCoachState(scope.row)"
              :type="scope.row.state === 1 ? 'danger' : 'primary'"
              size="mini"
            >
              {{ scope.row.state === 1 ? "禁用" : "恢复" }}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div style="height: 20px;"></div>
      <!--分页-->
      <el-pagination
        @current-change="getlist"
        :page-size="this.env.pageSize"
        :pager-count="7"
        background
        layout="prev, pager, next, total"
        :current-page.sync="page"
        :total="count"
      >
      </el-pagination>
    </div>
    <!--订单内容-->
    <el-dialog
      v-loading="loading"
      v-if="is_auth('store.coach.getcoach')"
      title="私教订单"
      :visible.sync="order_dialog"
      width="70%"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
    >
      <el-row>
        <OrderList ref="order_list" />
      </el-row>
    </el-dialog>
  </div>
</template>
<script>
import Template from "../../lesson/lesson/page/Template.vue";
import OrderList from "./page/OrderList";
export default {
  components: { OrderList },
  data() {
    return {
      Template,
      page_name: "教练列表",
      loading: true, // 加载状态
      issearch: false, // 搜索是否展示
      search: {}, // 搜索内容
      tableData: [], // 列表内容
      count: 0, // 数据总条数
      page: 1, // 当前页数
      order_dialog: false,
    };
  },
  // 创建
  created() {
    this.init();
  },
  // 安装
  mounted() {},
  methods: {
    // 权限判断
    is_auth(auth) {
      return this.Tool.is_auth(auth);
    },
    // 初始化
    init() {
      this.search = {
        user_name: "",
        user_nick: "",
        phone: "",
        identity_card: "",
        city: "",
      };
      this.is_search();
    },
    //搜索
    is_search() {
      this.page = 1;
      this.getlist();
    },
    // 获取列表
    getlist() {
      let postdata = {
        api_name: "coach.coachpool.getlist",
        token: this.Tool.get_l_cache("token"),
        pagesize: this.env.pageSize,
        page: this.page,
      };
      // 非必须参数（搜索条件）
      Object.assign(postdata, this.search);
      this.loading = true;
      this.Tool.post_data("oss", postdata, (json) => {
        this.loading = false;
        if (json.code === 0) {
          this.tableData = json.data.list;
          this.count = json.data.count;
        } else {
          this.tableData = [];
          this.count = 0;
          this.Tool.errormes(json);
        }
      });
    },
    // 查看
    tosee(coach_uuid = "") {
      this.$router.push({
        path: "/coach/coachpool/details",
        query: { coach_uuid: coach_uuid },
      });
    },
    changeCoachState(item) {
      const { coach_uuid, state } = item;
      let postdata = {
        api_name:
          state === 1
            ? "coach.coachpool.isdisable"
            : "coach.coachpool.isenable",
        token: this.Tool.get_l_cache("token"),
        coach_uuid,
      };
      this.Tool.post_data("oss", postdata, () => {
        this.getlist();
        this.$message({
          message: state === 1 ? "禁用成功！" : "恢复成功！",
          type: "success",
        });
      });
    },
    // 查看私教订单
    openOrderModel(coachInfo) {
      this.order_dialog = true;
      let thi = this;
      setTimeout(function() {
        thi.$refs.order_list.init(coachInfo);
      }, 300);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/*表格不换行*/
>>> .el-table__body .cell {
  white-space: nowrap;
  /*overflow: initial;*/
}

.form-tag_colour-div {
  min-height: 200px;
}
</style>
