<template>
  <div>
    <div class="page-content-search">
      <el-form
        ref="form"
        :model="search"
        size="mini"
        :label-width="this.env.search_label_width"
      >
        <el-row>
          <el-col :span="6">
            <el-form-item label="课程名称">
              <el-input v-model="search.lesson_name"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="学员昵称">
              <el-input v-model="search.user_nick"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="6">
            <el-form-item label="学员手机号">
              <el-input v-model="search.phone"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="订单号">
              <el-input v-model="search.order_no"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="3">
            <el-form-item>
              <el-button
                icon="el-icon-search"
                size="mini"
                type="primary"
                @click="is_search"
                >搜索
              </el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>

    <el-row>
      <el-col :span="24">
        <el-form
          :inline="true"
          size="medium"
          class="demo-form-inline"
          :label-width="this.env.label_width"
        >
          <el-form-item label="教练名称:">
            <span class="form-span-text">{{ coachInfo.user_nick }}</span>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <el-table
      size="medium"
      border
      v-loading="loading"
      :data="tableData"
      style="width: 100%"
    >
      <el-table-column prop="user_nick" label="学员名称" />
      <el-table-column prop="lesson_name" label="课程名称" width="140" />
      <el-table-column prop="store_name" label="门店名称" width="140" />
      <el-table-column prop="phone" label="学员手机" width="120" />
      <el-table-column prop="qty" label="规格">
        <template slot-scope="scope">
          <span>{{ `1v${scope.row.qty}` }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="number" label="节数">
        <template slot-scope="scope">
          <span>{{ `${scope.row.number}/${scope.row.lesson_sum}` }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="classroom_name" label="上课教室" width="120" />
      <el-table-column prop="order_no" label="订单号" />
      <el-table-column prop="lesson_state" label="是否核销">
        <template slot-scope="scope">
          <el-tag size="mini" v-if="scope.row.lesson_state === 1"
            >待上课</el-tag
          >
          <el-tag
            size="mini"
            type="success"
            v-else-if="scope.row.lesson_state === 2"
            >上课中</el-tag
          >
          <el-tag
            size="mini"
            type="info"
            v-else-if="scope.row.lesson_state === 3"
            >已下课</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column prop="start_time" label="预约时间" width="200">
        <template slot-scope="scope">
          {{
            scope.row.start_time === "1970-01-01 08:00"
              ? "无"
              : scope.row.start_time
          }}
        </template>
      </el-table-column>
    </el-table>
    <div style="height: 20px;"></div>
    <el-pagination
      @current-change="getlist"
      :page-size="this.env.pageSize"
      :pager-count="7"
      background
      layout="prev, pager, next, total"
      :current-page.sync="page"
      :total="count"
    >
    </el-pagination>
  </div>
</template>

<script>
// import Template from '../../../lesson/lesson/page/Template.vue';
export default {
  //   components: { Template },
  name: "OrderList",
  data() {
    return {
      coachInfo: {}, // 教练信息
      loading: false, // 加载状态
      count: 0, // 数据总条数
      page: 1, // 当前页数
      tableData: [], // 添加页面数据
      search: {}, // 搜索内容
    };
  },
  // 创建
  created() {
    // this.init()
  },
  methods: {
    init(coachInfo) {
      this.coachInfo = coachInfo;
      this.count = 0;
      this.tableData = [];

      this.search = {
        user_nick: "",
        phone: "",
        order_no: "",
      };
      this.is_search();
    },
    is_search() {
      this.page = 1;
      this.getlist();
    },
    // 权限判断
    is_auth(auth) {
      return this.Tool.is_auth(auth);
    },
    // 获取可选教练列表
    getlist() {
      let postdata = {
        api_name: "order.pt.getappointmentlist",
        token: this.Tool.get_l_cache("token"),
        coach_uuid: this.coachInfo.coach_uuid,
        pagesize: this.env.pageSize,
        page: this.page,
      };
      // 非必须参数（搜索条件）
      Object.assign(postdata, this.search);
      this.loading = true;
      this.Tool.post_data("oss", postdata, (json) => {
        this.loading = false;
        if (json.code === 0) {
          this.tableData = json.data.list;
          this.count = json.data.count;
        } else {
          this.tableData = [];
          this.count = 0;
          this.Tool.errormes(json);
        }
      });
    },
  },
};
</script>

<style scoped></style>
